$(function() {
    gsap.registerPlugin(ScrollTrigger);
    let resized;
    let windowWidth = $(window).outerWidth();
    let root = $('html, body');

    $(window).resize(function() {
        clearTimeout(resized);
        resized = setTimeout(resizedw, 100);
    });

    function resizeWidthOnly() { //return new window width if different than previous else return false. This makes sure height changes don't trigger resize events
        let currentWindowWidth = $(window).outerWidth();
        if (windowWidth !== currentWindowWidth) {
            windowWidth = currentWindowWidth;
            return windowWidth;
        } else {
            return false;
        }
    }

    function resizedw() {
        if (resizeWidthOnly() !== false) {
            // playSectionAnimation();
        }
    }

    /****************/
    /* Fade Up Hero */
    /****************/
    $(".fade-up").each(function(i, obj) {
        let _obj = $(obj);

        ScrollTrigger.create({
            trigger: _obj[0],
            start: "top bottom",
            onEnter: () => _obj.addClass("in-view")
        });
    });

    smoothScroll();

    function smoothScroll() {
        $(document).on("click", 'a[href^="#"]', function () {
            if ($.attr(this, 'href').length > 1) {
                let extaHeight = 1;

                if (windowWidth > 1500) {
                    extaHeight = 1.3;
                }

                root.animate({
                    scrollTop: $($.attr(this, 'href')).offset().top * extaHeight
                }, 300);
            }

            return false;
        });
    }

    /*************************/
    /* Hero Scroll Animation */
    /*************************/
    $(".hero-animation").each(function(i, obj) {
        let _obj = $(obj);
        let _this = $(this);
        let _speed = _obj.data("speed");

        ScrollTrigger.create({
            trigger: _obj.closest(".common-animation-trigger"),
            start: () => "top " + _obj.closest(".common-animation-trigger"),
            end: () => "+=" + _obj.offset().top - _obj.closest(".common-animation-trigger").outerHeight(),
            onUpdate: (self) => _obj.css("transform", "translateY(-" + self.progress * _speed + "px)"),
            invalidateOnRefresh: true,
            // markers: true
        });
    });

    $(".footer-animation").each(function(i, obj) {
        let _obj = $(obj);
        let _this = $(this);
        let _speed = _obj.data("speed");

        ScrollTrigger.create({
            trigger: _obj.closest(".common-animation-trigger"),
            start: () => "top center",
            end: () => "+=" + _obj.offset().top - _obj.closest(".common-animation-trigger").outerHeight(),
            onUpdate: (self) => _obj.css("transform", "translateY(-" + self.progress * _speed + "px)"),
            invalidateOnRefresh: true,
            // markers: true
        });
    });

    /**************************/
    /* Hero Twinkle Animation */
    /**************************/
    let twinkingStars = [];

    $(".r-holiday-common-twinkle").each(function(i, obj) {
        let _obj = $(obj);
        let _star = _obj.data("star");

        let twinkleSpeed = getRandomNumber(5, 10);
        let width = getRandomNumber(16, 50);

        twinkingStars[_star] = bodymovin.loadAnimation({
            container: _obj[0],
            rendered: "svg",
            loop: true,
            autoplay: true,
            path: "/json/animation-twinkle.json",
            setQuality: "low"
        });

        twinkingStars[_star].setSpeed(twinkleSpeed / 10);
        _obj.css("width", width + "px");
    });

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    /**************/
    /* I Wish Pin */
    /**************/
    $(".wish-pin").each(function(i, obj) {
        let _obj = $(obj);

        let endHeight = _obj.closest("section").hasClass("r-holiday-shp") ? _obj.closest("section").outerHeight() / 2 : _obj.closest("section").outerHeight() * 1.25;

        wishPinST = gsap.to(_obj[0], {
            scrollTrigger: {
                trigger: _obj[0],
                start: "center 20%",
                end: () => "+=" +
                    endHeight,
                pin: true,
                // markers: true,
                invalidateOnRefresh: true,
            }
        });
    });

    /***************************/
    /* No Kid Hungry Animation */
    /***************************/
    $(".r-holiday-nkh-animation").each(function(i, obj) {
        let _obj = $(obj);
        let _position = _obj.data("position");

        gsap.to(_obj[0], {
            scrollTrigger: {
                trigger: _obj[0],
                start: "10% center",
                end: "+=1",
                once: true,
                invalidateOnRefresh: true,
                // markers: true
            },
            x: _position === "left" ? _obj.outerWidth() / 2 : -_obj.outerWidth() / 2,
            duration: 1
        });
    });

    /***************************************/
    /* Trevor project Heart/Copy Animation */
    /***************************************/
    gsap.to(".r-holiday-tp-copy", {
        scrollTrigger: {
            trigger: $(".r-holiday-tp-animation-wrp"),
            start: "top center",
            end: "+=" +
                $(".r-holiday-tp-animation-wrp").outerHeight() / 3,
            onUpdate: function (self) {
                let _progress = 100 - (self.progress * 100);
                $(".r-holiday-tp-copy").css("transform", "translateY(" + _progress + "%)");
            },
            invalidateOnRefresh: true,
            once: true,
            // markers: true
        }
    });

    /**************************************/
    /* Sets Up & Triggers JSON Animations */
    /**************************************/
    let sectionAnimations = [];

    $(".section-animation").each(function (i, obj) {
        let _obj = $(obj);
        let _animation = _obj.data("animation");

        sectionAnimations[_animation] = bodymovin.loadAnimation({
            container: _obj[0],
            rendered: "svg",
            loop: true,
            autoplay: false,
            path: "/json/" + _animation + ".json",
            setQuality: "low"
        });

        ScrollTrigger.create({
            trigger: _obj[0],
            start: "top 90%",
            invalidateOnRefresh: true,
            // markers: true,
            onEnter: function () {
                triggerJsonAnimations();
            },
            onLeave: function () {
                triggerJsonAnimations();
            },
            onEnterBack: function () {
                triggerJsonAnimations();
            },
            onLeaveBack: function () {
                triggerJsonAnimations();
            },
            // markers: true
        });
    });

    function triggerJsonAnimations() {
        $(".section-animation").each(function (i, obj) {
            let _obj = $(obj);
            let _animation = _obj.data("animation");


            if (_obj.isInViewport()) {
                sectionAnimations[_animation].play();
            } else {
                sectionAnimations[_animation].pause();
            }
        });
    }

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).outerHeight();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };
});